<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="8">
                <b-card>
                    <table class="w-full table">
                        <tr>
                            <td>Aset</td>
                            <td>:</td>
                            <th>{{ asset ? asset.nama : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Jumlah</td>
                            <td>:</td>
                            <th>{{ mutasi.jumlah }}</th>
                        </tr>
                        <tr>
                            <td>Satuan</td>
                            <td>:</td>
                            <th>{{ asset && asset.satuan ? asset.satuan.satuan : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Lokasi Awal Mutasi</td>
                            <td>:</td>
                            <th>
                                <span v-if="mutasi.dari_gudang">{{ mutasi.dari_gudang.nama_gudang}}</span>
                                <i class="text-danger" v-else>Lokasi awal tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Tujuan Mutasi</td>
                            <td>:</td>
                            <th>
                                <span v-if="mutasi.tujuan_gudang">{{ mutasi.tujuan_gudang.nama_gudang}}</span>
                                <i class="text-danger" v-else>Tujuan tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>:</td>
                            <th>
                                <b-badge variant="light-success" v-if="mutasi && mutasi.terima == 1">Diterima</b-badge>
                                <b-badge variant="light-danger" v-else>Belum Diterima</b-badge>
                            </th>
                        </tr>
                    </table>
                    <br>
                    <div class="d-flex justify-content-end">
                        <b-button :variant="mutasi && mutasi.terima == 1 ? 'secondary'  :'success'" :disabled="mutasi && mutasi.terima == 1" @click.prevent="choosePenyimpanan">{{ mutasi && mutasi.terima == 1 ? 'Mutasi sudah diterima' : 'Terima Mutasi' }}</b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <confirm-modal @submit="submit"></confirm-modal>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BButton, BBadge} from 'bootstrap-vue'
import ConfirmModal from './components/Modal.vue'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BButton, BBadge, ConfirmModal
    },
    data: () => ({
        loading: false,
        gudangs: [],
        asset: null,
        penyimpanan_dari: null
    }),
    computed: {
        mutasi() {
            return this.$store.getters['asset/searchEntryMutation'](this.$route.params.id)
        },
        penyimpanans() {
            const penyimpanans = JSON.parse( JSON.stringify(this.$store.state.asset.stocks) )
            penyimpanans.map(item => item.jumlah = item.stok)
            return penyimpanans
        }
    },
    methods: {
        async submit({penyimpanan_tujuan, terima}) {
            const payload = {
                id: this.mutasi.id,
                dari: this.mutasi.dari,
                tujuan: this.mutasi.tujuan,
                asset_id: this.mutasi.asset_id,
                jumlah: this.mutasi.jumlah,
                penyimpanan_dari: this.mutasi.penyimpanan_dari,
                penyimpanan_tujuan,
                terima
            }

            try {
                this.loading = true
                await this.$store.dispatch('asset/saveRequestMutation', [payload])
                this.displaySuccess({
                    message: 'Mutasi telah ditempatkan!'
                })
                this.$bvModal.hide('confirm-modal')
                this.loading = false
                setTimeout(() => {this.$router.push(`/asset-mutasi-entry`)}, 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        choosePenyimpanan() {
            if(this.penyimpanans.length < 1) {
                this.displayError({
                    message: 'Penyimpanan barang belum ada, harap melakukan buat penyimpanan terlebih dahulu'
                })
                return false
            }
            this.$bvModal.show('confirm-modal')
        },
        getGudangName(gudang_id) {
            const find = this.gudangs.find(item => item.id == gudang_id)
            return find ? find.nama_gudang : '-'
        },
        async getAsset() {
            this.asset = await this.$store.dispatch('asset/getBarangById', this.mutasi.asset_id)
        },
        async getPenyimpananDari() {
            this.penyimpanan_dari = await this.$store.dispatch('asset/getStockById', this.mutasi.penyimpanan_dari)
        },
        async getGudang() {
            this.gudangs = await this.$store.dispatch('gudang/getData', {jenis: 1})
        },
        async getPenyimpanan(asset_id) {
            const params = {order: 'desc', asset_id}
            if(this.myGudang) params.gudang_id = this.myGudang.id

            await this.$store.dispatch('asset/getStock', params)
        }
    },
    async created() {
        if(!this.mutasi) {
            this.$router.push(`/asset-mutasi-request`)
        }
        await this.getGudang()
    },
    mounted() {
        this.getAsset()
        this.getPenyimpananDari()
        this.getPenyimpanan(this.mutasi.asset_id)
    }
}
</script>