var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "8"
    }
  }, [_c('b-card', [_c('table', {
    staticClass: "w-full table"
  }, [_c('tr', [_c('td', [_vm._v("Aset")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.asset ? _vm.asset.nama : '-'))])]), _c('tr', [_c('td', [_vm._v("Jumlah")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.mutasi.jumlah))])]), _c('tr', [_c('td', [_vm._v("Satuan")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.asset && _vm.asset.satuan ? _vm.asset.satuan.satuan : '-'))])]), _c('tr', [_c('td', [_vm._v("Lokasi Awal Mutasi")]), _c('td', [_vm._v(":")]), _c('th', [_vm.mutasi.dari_gudang ? _c('span', [_vm._v(_vm._s(_vm.mutasi.dari_gudang.nama_gudang))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Lokasi awal tidak ditemukan")])])]), _c('tr', [_c('td', [_vm._v("Tujuan Mutasi")]), _c('td', [_vm._v(":")]), _c('th', [_vm.mutasi.tujuan_gudang ? _c('span', [_vm._v(_vm._s(_vm.mutasi.tujuan_gudang.nama_gudang))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Tujuan tidak ditemukan")])])]), _c('tr', [_c('td', [_vm._v("Status")]), _c('td', [_vm._v(":")]), _c('th', [_vm.mutasi && _vm.mutasi.terima == 1 ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v("Diterima")]) : _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("Belum Diterima")])], 1)])]), _c('br'), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": _vm.mutasi && _vm.mutasi.terima == 1 ? 'secondary' : 'success',
      "disabled": _vm.mutasi && _vm.mutasi.terima == 1
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.choosePenyimpanan($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.mutasi && _vm.mutasi.terima == 1 ? 'Mutasi sudah diterima' : 'Terima Mutasi'))])], 1)])], 1)], 1), _c('confirm-modal', {
    on: {
      "submit": _vm.submit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }