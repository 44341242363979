var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "confirm-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "title": "Pilih Tempat Penyimpanan"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.selectedPenyimpanan
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(" Simpan ")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.penyimpanans
    },
    scopedSlots: _vm._u([{
      key: "cell(radio)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "name": "stok",
            "value": item
          },
          model: {
            value: _vm.selectedPenyimpanan,
            callback: function ($$v) {
              _vm.selectedPenyimpanan = $$v;
            },
            expression: "selectedPenyimpanan"
          }
        })];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '') + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }