<template>
    <b-modal id="confirm-modal" no-close-on-backdrop no-close-on-esc size="lg" title="Pilih Tempat Penyimpanan">
        <b-table :fields="fields" :items="penyimpanans">
            <template #cell(radio)="{item}">
                <b-form-radio name="stok" v-model="selectedPenyimpanan" :value="item"></b-form-radio>
            </template>
            <template #cell(blok)="{item}">
                {{item.blok ? item.blok.blok : '-'}}
            </template>
            <template #cell(satuan)="{item}">
                {{item.asset && item.asset.satuan ? item.asset.satuan.satuan : ''}}
            </template>
        </b-table>
        <template #modal-footer>
            <b-button variant="primary" :disabled="!selectedPenyimpanan" @click.prevent="submit">
                Simpan
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BTable, BFormRadio, BButton} from 'bootstrap-vue'
export default {
    components: {
        BModal, BTable, BFormRadio, BButton
    },
    data: () => ({
        selectedPenyimpanan: null,
        fields: [
            {
                key: 'radio',
                label: '#'
            },
            {
                key: 'blok',
                label: 'Lokasi Aset'
            },
            {
                key: 'stok',
                label: 'Stok'
            },
            {
                key: 'satuan',
                label: 'Satuan'
            }
        ]
    }),
    computed: {
        penyimpanans() {
            const penyimpanans = JSON.parse( JSON.stringify(this.$store.state.asset.stocks) )
            penyimpanans.map(item => item.jumlah = item.stok)
            return penyimpanans
        }
    },
    methods: {
        submit() {
            if(!this.selectedPenyimpanan) {
                this.displayError({
                    message: 'Harap pilih tempat penyimpanan!'
                })
                return false
            }

            this.$emit('submit', {
                penyimpanan_tujuan: this.selectedPenyimpanan.id,
                terima: 1
            })
        }
    }
}
</script>